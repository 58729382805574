import React from "react";
import  {useNavigate} from 'react-router-dom';
import styled from "styled-components";
import Clock from "../../../../assets/icons/alarm.svg";
import Eye from "../../../../assets/icons/visibility.svg";
import { colors } from "../../../includes/constants/Colors";

function RecipieCard({userName,recipe,responsive,index,activeRecipe,setActiveRecipe}) {

    const navigate = useNavigate()

    const handleRecipe = () =>{
        navigate("/recipies/single-recipe")
    }

    return (
        <MainContainer 
            onClick={responsive ? ()=>handleRecipe() : ()=>setActiveRecipe(recipe)}
            className={activeRecipe.date_added===recipe.date_added? 'active' : ""}
        >
            <Thumbnail>
                <img src={recipe.image} alt={recipe.name} />
            </Thumbnail>
            <Content>
                <TopContent>
                    <PersonName>{userName}</PersonName>
                    <RecipieName>
                        {recipe.name}
                    </RecipieName>
                </TopContent>
                <BottomContent>
                    <Box>
                        <Icon>
                            <img src={Clock} alt="time" />
                        </Icon>
                        <Text>{recipe.cooking_time}</Text>
                    </Box>
                    <Box>
                        <Icon>
                            <img src={Eye} alt="visibility" />
                        </Icon>
                        <Text>200 views</Text>
                    </Box>
                </BottomContent>
            </Content>
        </MainContainer>
    );
}
const MainContainer = styled.div`
    border-radius: 8px;
    border: 1px solid ${colors.lightGray};
    display: flex;
    gap: 10px;
    padding: 5px;
    cursor: default;
    &.active{
        border: 1px solid ${colors.primary};
    }
`;
const Thumbnail = styled.div`
    width: 140px;
    height: 120px;
    border-radius: 8px;
    overflow: hidden;
    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;
const Content = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
    min-height: 120px;
    flex-grow: 1;
    // @media all and (max-width: 1280px){
    //     width: 100%;
    // }
`;
const TopContent = styled.div`
    flex: 1;
`;
const PersonName = styled.div`
    color: ${colors.fontColorSecondary};
    font-family: "dm_sansregular";
    font-size: 14px;
    margin-bottom: 5px;
`;
const RecipieName = styled.div`
    font-family: "dm_sansmedium";
    font-size: 16px;
    margin-bottom: 5px;
    line-height: 1.2;
`;
const BottomContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
`;
const Box = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;
const Icon = styled.div`
    width: 10px;
`;
const Text = styled.div`
    font-family: "dm_sansregular";
    font-size: 14px;
`;
export default RecipieCard;

import "./App.css";
import "./assets/css/style.css";
import Store from "./contexts/Store";
import MainRouter from "./routing/routers/MainRouter";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ComingSoon from "./components/includes/ComingSoon";

function App() {
    return (
        <ComingSoon />
        // <Store>
        //     <MainRouter />
        // </Store>
    );
}

export default App;

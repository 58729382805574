import React from 'react'
import styled from "styled-components";
import { colors } from "../../includes/constants/Colors";

const PrimaryButton = ({text, onclick, classname}) => {
  return (
    <Button
        onClick={onclick}
        className={classname}
    >
        {text}
    </Button>
  )
}

export default PrimaryButton

const Button = styled.button`
    padding: 16px 32px;
    color: #fff;
    background-color: ${colors.primary};
    border-radius: 8px;
    font-family: "dm_sansregular";
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    &.link{
        font-size: 16px;
        font-weight: 500;
        color: ${colors.primary};
        background-color: transparent;
        text-decoration: underline;
        padding: 16px 0;
    }
    &.cancel{
        color: ${colors.red};
    }
    &.add{
        font-size: 18px;
        font-weight: 500;
    }
`;
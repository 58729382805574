import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Outlet } from "react-router-dom";

import { Context } from "../../../contexts/Store";
import { baseConfig } from "../../../axiosConfig";
import { colors } from "../../includes/constants/Colors";
import RecipieCard from "./includes/RecipieCard";
import ViewGallerySlider from "../restuarant-single-page/ViewGallerySlider";
// images
import Alarm from "../../../assets/images/recipies/alarm.svg";
import Cooking from "../../../assets/images/recipies/cooking.svg";
import Fire from "../../../assets/images/recipies/fire.svg";
import Eye from "../../../assets/images/recipies/visibility.svg";
import Ingredients from "./includes/Ingredients";
import Preparations from "./includes/Preparations";
import Others from "./includes/Others";
import RecipieEmptyPage from "../../includes/empty-pages/RecipieEmptyPage";
import { useState } from "react";
import PrimaryButton from "../../includes/buttons/PrimaryButton";
import Loader from "../../includes/Loader";

function RecipiesPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const details = searchParams?.get("details");
  const navigate = useNavigate();
  const { state } = useContext(Context);

  const [empty, setEmpty] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [recipes, setRecipes] = useState([]);
  const [userName, setUserName] = useState("");
  const [userImage, setUserImage] = useState("");
  const [activeRecipe, setActiveRecipe] = useState({});
  const [sliderData, setSliderData] = useState([]);

  // const sliderData = [
  //   {
  //     id: 1,
  //     file_type: "10",
  //     file: "https://images.unsplash.com/photo-1565299624946-b28f40a0ae38?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8Zm9vZHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60",
  //   },
  //   {
  //     id: 2,
  //     file_type: "10",
  //     file: "https://images.unsplash.com/photo-1565299624946-b28f40a0ae38?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8Zm9vZHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60",
  //   },
  //   {
  //     id: 3,
  //     file_type: "10",
  //     file: "https://images.unsplash.com/photo-1565299624946-b28f40a0ae38?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8Zm9vZHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60",
  //   },
  // ];

  const getProfile = () => {
    let access_token = state.user_details.access_token;
    setLoading(true);
    baseConfig
      .get("register/get-profile/", {
        headers: {
          Authorization: "Bearer " + access_token,
        },
      })
      .then((response) => {
        let { StatusCode, data, message } = response.data;
        if (StatusCode === 6000) {
          setLoading(false);
          setUserName(data.name);
          setUserImage(data.photo);
        } else {
          alert(message);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getRecipes = () => {
    setLoading(true);
    let access_token = state.user_details.access_token;
    baseConfig
      .get("users/recipes/", {
        headers: {
          Authorization: "Bearer " + access_token,
        },
      })
      .then((response) => {
        let { StatusCode, data } = response.data;
        if (StatusCode === 6000) {
          setLoading(false);
          setRecipes(data);
          setActiveRecipe(data[0]);
        }
      })
      .catch((error) => {
        setLoading(false);
        alert(error);
      });
  };

  const getFormattedDate = (date_string) => {
    const date = new Date(date_string);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });

    let daySuffix = "th";
    if (day === 1 || day === 21 || day === 31) {
      daySuffix = "st";
    } else if (day === 2 || day === 22) {
      daySuffix = "nd";
    } else if (day === 3 || day === 23) {
      daySuffix = "rd";
    }

    const formattedDate = `${day}${daySuffix} ${month}`;
    return formattedDate;
  };

  useEffect(() => {
      getProfile();
      getRecipes();
      let details = "ingredients";
      setSearchParams({ details });
  }, []);

  return (
    <MainBox>
      {isLoading ? (
        <Loader />
      ) : recipes?.length === 0 ? (
        <RecipieEmptyPage />
      ) : (
        <MainContainer>
          <TopContainer>
            <div className="wrapper">
              <Title>Recipies</Title>
              <PrimaryButton
                text="+Add Recipe"
                onclick={() => navigate("/recipies/add-recipies")}
                classname="add"
              />
            </div>
          </TopContainer>
          <BottomContainer>
            <div className="wrapper">
              <BottomInnerContainer>
                <BottomLeft>
                  {recipes.map((recipe, index) => (
                    <RecipieCard
                      key={index}
                      userName={userName}
                      recipe={recipe}
                      index={index}
                      activeRecipe={activeRecipe}
                      setActiveRecipe={setActiveRecipe}
                    />
                  ))}
                </BottomLeft>
                <BottomLeft className="responsive">
                  {recipes.map((recipe, index) => (
                    <RecipieCard
                      key={index}
                      userName={userName}
                      recipe={recipe}
                      responsive
                      index={index}
                      activeRecipe={activeRecipe}
                      setActiveRecipe={setActiveRecipe}
                    />
                  ))}
                </BottomLeft>
                <BottomRight>
                  <ViewGallerySlider
                    data={activeRecipe.video ?[
                      { id: "1", file_type: "10", file: activeRecipe.image },
                      { id: "2", file_type: "10", file: activeRecipe.video },
                    ]:[{ id: "1", file_type: "10", file: activeRecipe.image }]
                  }
                  />
                  <ContentContainer>
                    <Heading>
                      {activeRecipe.name}
                      <CategoryBox>
                        {activeRecipe.dietary_type === "10" && (
                          <CategoryIcon
                            src="https://tegain-project-assets.sgp1.digitaloceanspaces.com/guester/icons/veg-icon.svg"
                            alt="category-icon"
                          />
                        )}
                        {activeRecipe.dietary_type === "20" && (
                          <CategoryIcon
                            src="https://tegain-project-assets.sgp1.digitaloceanspaces.com/guester/icons/non-veg-icon.svg"
                            alt="category-icon"
                          />
                        )}
                      </CategoryBox>
                    </Heading>
                    <ProfileBox>
                      <ProfileImageBox>
                        {userImage ? (
                          <ProfileImg src={userImage} alt="profile-picture" />
                        ) : (
                          <ProfileImg
                            src="https://tegain-project-assets.sgp1.digitaloceanspaces.com/guester/profile-image.jpg"
                            alt="profile-image"
                          />
                        )}
                      </ProfileImageBox>
                      <ProfileDetails>
                        <ProfileName>{userName}</ProfileName>
                        <PostDate>
                          Posted on {getFormattedDate(activeRecipe.date_added)}
                        </PostDate>
                      </ProfileDetails>
                    </ProfileBox>
                    <RecipieDetails>
                      <RecipieDetailItem>
                        <RecipieDetailIcon>
                          <RecipieDetailImg
                            src={Alarm}
                            alt="recipie-details-icon"
                          />
                        </RecipieDetailIcon>
                        <RecipieDetailName>
                          {activeRecipe.cooking_time}
                        </RecipieDetailName>
                      </RecipieDetailItem>
                      <RecipieDetailItem>
                        <RecipieDetailIcon>
                          <RecipieDetailImg
                            src={Eye}
                            alt="recipie-details-icon"
                          />
                        </RecipieDetailIcon>
                        <RecipieDetailName>200 Views</RecipieDetailName>
                      </RecipieDetailItem>
                      <RecipieDetailItem>
                        <RecipieDetailIcon>
                          <RecipieDetailImg
                            src={Cooking}
                            alt="recipie-details-icon"
                          />
                        </RecipieDetailIcon>
                        <RecipieDetailName>
                          {activeRecipe.served_peoples} People Servings
                        </RecipieDetailName>
                      </RecipieDetailItem>
                      <RecipieDetailItem>
                        <RecipieDetailIcon>
                          <RecipieDetailImg
                            src={Fire}
                            alt="recipie-details-icon"
                          />
                        </RecipieDetailIcon>
                        <RecipieDetailName>
                          {activeRecipe.calories} Cal
                        </RecipieDetailName>
                      </RecipieDetailItem>
                    </RecipieDetails>
                    <DishCategory>
                      {activeRecipe.recipe_categories?.map((category) => (
                        <DishCategoryItem key={category.id}>
                          {category.name}
                        </DishCategoryItem>
                      ))}
                    </DishCategory>
                  </ContentContainer>
                  <TabsDetails>
                    <Tabs>
                      <TabItem
                        onClick={() => {
                          let details = "ingredients";
                          setSearchParams({
                            details,
                          });
                        }}
                        className={
                          searchParams?.get("details") === "ingredients"
                            ? "active"
                            : ""
                        }
                      >
                        Ingredients
                      </TabItem>
                      <TabItem
                        onClick={() => {
                          let details = "preparation";
                          setSearchParams({
                            details,
                          });
                        }}
                        className={
                          searchParams?.get("details") === "preparation"
                            ? "active"
                            : ""
                        }
                      >
                        Preparation
                      </TabItem>
                      <TabItem
                        onClick={() => {
                          let details = "Other";
                          setSearchParams({
                            details,
                          });
                        }}
                        className={
                          searchParams?.get("details") === "Other"
                            ? "active"
                            : ""
                        }
                      >
                        Other
                      </TabItem>
                    </Tabs>
                    <TabContent>
                      {details === "ingredients" ? (
                        <Ingredients ingredients={activeRecipe.ingredients} />
                      ) : details === "preparation" ? (
                        <Preparations preparation={activeRecipe.preparation} />
                      ) : details === "Other" ? (
                        <Others />
                      ) : null}
                    </TabContent>
                  </TabsDetails>
                </BottomRight>
              </BottomInnerContainer>
            </div>
          </BottomContainer>
        </MainContainer>
      )}
    </MainBox>
  );
}
const MainBox = styled.div`
  width: 100%;
  min-height: 100vh;
`;
const MainContainer = styled.div``;
const TopContainer = styled.div`
  padding: 80px 0 30px;
  background-color: #f5f5f5;
  & .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
const Title = styled.h4`
  font-size: 24px;
  font-family: "dm_sansmedium";
`;
const BottomContainer = styled.div`
  background-color: #fff;
  padding: 50px 0;
`;
const BottomInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
  @media all and (max-width: 1280px) {
    width: 80%;
    margin: 0 auto;
  }
`;
const BottomLeft = styled.div`
  /* min-width: 400px; */
  width: 30%;
  height: 900px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 15px;
  &.responsive {
    display: none;
  }
  @media all and (max-width: 1280px) {
    width: 100%;
    display: none;
    &.responsive {
      display: flex;
    }
  }
`;
const BottomRight = styled.div`
  flex: 1;
  width: 60%;
  background-color: #fff;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0px 4px 56px rgba(0, 0, 0, 0.45);
  @media all and (max-width: 1280px) {
    display: none;
  }
`;
const ContentContainer = styled.div`
  padding: 15px 0;
  margin-bottom: 20px;
`;
const Heading = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
  font-family: "dm_sansmedium";
  font-size: 18px;
  margin-bottom: 10px;
`;
const CategoryBox = styled.span`
  width: 20px;
  display: flex;
`;
const CategoryIcon = styled.img``;
const ProfileBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
`;
const ProfileImageBox = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 2px;
  border: 1px solid #c5c5c5;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 7px 0px #00000026;
`;
const ProfileImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;
const ProfileDetails = styled.div``;
const ProfileName = styled.div`
  font-family: "dm_sansmedium";
  font-size: 16px;
`;
const PostDate = styled.div`
  font-family: "dm_sansregular";
  font-size: 14px;
`;
const RecipieDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 5px;
  width: fit-content;
  margin-bottom: 20px;
`;
const RecipieDetailItem = styled.div`
  width: 200px;
  display: flex;
  align-items: center;
  gap: 5px;
`;
const RecipieDetailIcon = styled.div`
  width: 20px;
`;
const RecipieDetailImg = styled.img`
  width: 100%;
`;
const RecipieDetailName = styled.div`
  font-family: "dm_sansregular";
  font-size: 14px;
`;
const DishCategory = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const DishCategoryItem = styled.div`
  border-radius: 16px;
  padding: 6px 10px;
  background-color: #f3f4f5;
  font-family: "dm_sansregular";
  font-size: 14px;
  color: #a0a0a0;
`;
const TabsDetails = styled.div``;
const Tabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 30px;
`;
const TabItem = styled.div`
  font-family: "dm_sansmedium";
  font-size: 16px;
  width: fit-content;
  padding-bottom: 3px;
  cursor: pointer;
  &.active {
    border-bottom: 2px solid ${colors.primary};
  }
`;
const TabContent = styled.div``;
export default RecipiesPage;

import React from "react";
import styled from "styled-components";
import Lottie from "react-lottie";

// constants
import { colors } from "./constants/Colors";
import animationData from "./../../assets/lotties/button-loader.json";

export default function Button({
    title,
    bgColor,
    textColor,
    borderColor,
    width,
    height,
    margin,
    onClick,
    padding,
    type,
    isLoading,
    fontSize,
    fontFamily,
    fontColor,
    cursor,
}) {
    return (
        <Container
            bgColor={bgColor}
            borderColor={borderColor}
            width={width}
            margin={margin}
            padding={padding}
            type={type}
            height={height}
            cursor={cursor}
            onClick={(e) => {
                e.preventDefault();
                onClick();
            }}
        >
            {isLoading ? (
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: animationData,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice",
                        },
                    }}
                    height={22}
                    width={22}
                    // eventListeners={eventListeners}
                />
            ) : (
                <Text
                    fontColor={fontColor}
                    fontSize={fontSize}
                    fontFamily={fontFamily}
                >
                    {title}
                </Text>
            )}
        </Container>
    );
}

const Container = styled.div`
    height: ${({ height }) => (height ? height : "48px")};
    width: ${(props) => (props.width ? props.width : "fit-content")};
    background-color: ${({ bgColor }) => (bgColor ? bgColor : colors.primary)};
    color: ${(props) => (props.textColor ? props.textColor : "#fff")};
    border: 2px solid;
    border-color: ${(props) => (props.borderColor ? props.borderColor : "")};
    border-radius: 8px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: ${({ cursor }) => (cursor ? cursor : "pointer")};
    margin-top: ${(props) => (props.margin ? props.margin : "")};
    padding: ${({ padding }) => (padding ? `${padding}` : "10px 20px")};
    @media (max-width: 1280px) {
        height: ${({ height }) => (height ? height : "48px")};
    }
    @media all and (max-width: 768px) {
        height: ${({ height }) => (height ? height : "44px")};
    }
    @media all and (max-width: 640px) {
        padding: 10px 15px;
    }
    @media all and (max-width: 550px) {
        width: ${(props) => (props.width ? "100%" : "fit-content")};
    }
    @media all and (max-width: 480px) {
        height: ${({ height }) => (height ? height : "40px")};
    }
`;

const Text = styled.span`
    color: ${({ fontColor }) => (fontColor ? fontColor : "")};
    font-size: ${({ fontSize }) => (fontSize ? fontSize : "16px")};
    user-select: none;
    font-family: ${({ fontFamily }) =>
        fontFamily ? `${fontFamily}` : "dm_sansmedium"};
    @media (max-width: 768px) {
        font-size: ${({ fontSize }) => (fontSize ? fontSize : "14px")};
    }
`;

import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Context } from "../../../contexts/Store";
import { baseConfig } from "../../../axiosConfig";
import { colors } from "../../includes/constants/Colors";

import PrimaryButton from "../../includes/buttons/PrimaryButton";
import FirstStep from "./includes/FirstStep";
import SecondStep from "./includes/SecondStep";
import ThirdStep from "./includes/ThirdStep";
import Loader from "../../includes/Loader";

function AddRecipies() {
  const navigate = useNavigate();
  const { state } = useContext(Context);
  const [isLoading, setLoading] = useState(true);
  const [stepIndex, setStepIndex] = useState(1);
  const [showErrors, setShowErrors] = useState(false);

  // step 1
  const [recipeName, setRecipeName] = useState("");
  const [recipeType, setRecipeType] = useState(0);
  const [imageFiles, setImageFiles] = useState([]);
  const [videoFiles, setVideoFiles] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  // const [categories, setCategories] = useState([
  //   {
  //     id: 1,
  //     name: "Appetizer",
  //   },
  //   {
  //     id: 2,
  //     name: "Beef",
  //   },
  //   {
  //     id: 3,
  //     name: "Beverage",
  //   },
  //   {
  //     id: 4,
  //     name: "Bread",
  //   },
  //   {
  //     id: 5,
  //     name: "Breakfast",
  //   },
  //   {
  //     id: 6,
  //     name: "Cake",
  //   },
  //   {
  //     id: 7,
  //     name: "Candy",
  //   },
  //   {
  //     id: 8,
  //     name: "Fruit",
  //   },
  //   {
  //     id: 9,
  //     name: "Casserole",
  //   },
  //   {
  //     id: 10,
  //     name: "Chicken",
  //   },
  //   {
  //     id: 11,
  //     name: "Chinese",
  //   },
  //   {
  //     id: 12,
  //     name: "Cookies",
  //   },
  //   {
  //     id: 13,
  //     name: "Crockpot",
  //   },
  //   {
  //     id: 14,
  //     name: "Dessert",
  //   },
  //   {
  //     id: 15,
  //     name: "French",
  //   },
  //   {
  //     id: 16,
  //     name: "Italian",
  //   },
  //   {
  //     id: 17,
  //     name: "German",
  //   },
  //   {
  //     id: 18,
  //     name: "Holiday",
  //   },
  //   {
  //     id: 19,
  //     name: "Jams and Jellie",
  //   },
  //   {
  //     id: 20,
  //     name: "Japanese",
  //   },
  //   {
  //     id: 21,
  //     name: "Lunch",
  //   },
  //   {
  //     id: 22,
  //     name: "Pie",
  //   },
  //   {
  //     id: 23,
  //     name: "Salad",
  //   },
  //   {
  //     id: 24,
  //     name: "Mexican",
  //   },
  //   {
  //     id: 25,
  //     name: "Main Course",
  //   },
  //   {
  //     id: 26,
  //     name: "Rice",
  //   },
  //   {
  //     id: 27,
  //     name: "Sandwich",
  //   },
  //   {
  //     id: 28,
  //     name: "Sauce",
  //   },
  //   {
  //     id: 29,
  //     name: "Seafood",
  //   },
  //   {
  //     id: 30,
  //     name: "Side Dish",
  //   },
  //   {
  //     id: 31,
  //     name: "Soup",
  //   },
  //   {
  //     id: 32,
  //     name: "Southern",
  //   },
  //   {
  //     id: 33,
  //     name: "Thai",
  //   },
  //   {
  //     id: 34,
  //     name: "Turkey",
  //   },
  //   {
  //     id: 35,
  //     name: "Vegetarian",
  //   },
  // ]);

  // step2
  const [categories, setCategories] = useState([])
  const [cookingTime, setCookingTime] = useState(5)
  const [servings, setServings] = useState(0)
  const [calory, setCalory] = useState(0)

  // step3
  const [ingredients,setIngredients] = useState("")
  const [preparation,setPreparation] = useState("")

  const getCategories = () => {
    setLoading(true);
    baseConfig
      .get("users/recipe-categories/", {})
      .then((response) => {
          let { StatusCode, data, message } = response.data;
          if (StatusCode === 6000) {
              setLoading(false);
              setCategories(data);
          } else {
              alert(message);
          }
      })
      .catch((error) => {
          alert(error);
      });
  };

  useEffect(() => {
    getCategories();
    window.scrollTo(0, 0);
  }, []);

  const handlePrevious = (event) => {
    event.preventDefault();
    setStepIndex((prev) => prev - 1);
  };

  const handleNext = (event, step) => {
    event.preventDefault();
    if (step === 1) {
      if (
        recipeName &&
        recipeType &&
        (imageFiles.length !== 0) &&
        (selectedCategories.length !==0)
      ) {
        setStepIndex((prev) => prev + 1);
        setShowErrors(false);
      } else setShowErrors(true);
    }else{
      setStepIndex((prev) => prev + 1);
    }
  };

  const clearStates = () =>{
    setRecipeName("");
    setRecipeType("");
    setImageFiles("");
    setVideoFiles("");
    setSelectedCategories([]);
    setCookingTime(0)
    setServings(0)
    setCalory(0)
    setIngredients("")
    setPreparation("")
    setShowErrors(false);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if(ingredients && preparation){
      // change time format
      let formatedTime = ""
      if(cookingTime < 60){
        formatedTime = `${cookingTime} min`
      }else{
        const hour = Math.floor(cookingTime/60)
        const minutes = cookingTime % 60
        if(minutes === 0)
          formatedTime = `${hour} hr`
        else
          formatedTime = `${hour} hr ${minutes} min`
      }

      const formData = new FormData()
      formData.append("name",recipeName)
      formData.append("dietary_type",recipeType)
      formData.append("recipe_types",selectedCategories)
      formData.append("image",imageFiles[0])
      // formData.append("video",videoFiles[0])
      formData.append("calories",calory)
      formData.append("served_peoples",servings)
      formData.append("cooking_time",formatedTime)
      formData.append("ingredients",ingredients)
      formData.append("preparation",preparation)
      videoFiles[0] && formData.append("video",videoFiles[0])
      
      setLoading(true);
      let access_token = state.user_details.access_token;
          baseConfig
              .post("users/post-recipe/",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: "Bearer " + access_token,
                },
              })
              .then((response) => {
                  let { StatusCode, message } = response.data;
                  if (StatusCode === 6000) {
                      setTimeout(() => {
                          setLoading(false);
                      }, 300);
                      console.log("Submitted")
                      clearStates();
                      navigate(-1);
                  } else {
                      setLoading(false);
                      // setError(message);
                      console.log("MESSAGE:",message)
                  }
              })
              .catch((error) => {
                  setLoading(false);
                  // const keyValueStringObject = Object.entries(
                  //     error?.response?.data
                  // )?.reduce((acc, [key, [value]]) => {
                  //     acc[key] = String(value);
                  //     return acc;
                  // }, {});
                  // setError(keyValueStringObject);
                  console.log("ERROR:",error);
              });
    }else{
      setShowErrors(true);
    }
  };

  const handleCancel = (event) => {
    event.preventDefault();
    clearStates();
    navigate(-1);
  };

  return (
    <MainContainer>
      {isLoading ? (
        <Loader />
      ) :(
      <>
        <TopContainer>
          <div className="wrapper">
            <Title>Add Recipes</Title>
            <StepsBox>
              <StepItem
                className={
                  stepIndex === 1 ? "active" : stepIndex > 1 ? "completed" : ""
                }
              >
                <StepIndex
                  className={
                    stepIndex === 1 ? "active" : stepIndex > 1 ? "completed" : ""
                  }
                >
                  <span>1</span>
                </StepIndex>
                Step 1
              </StepItem>
              <StepItem
                className={
                  stepIndex === 2 ? "active" : stepIndex > 2 ? "completed" : ""
                }
              >
                <StepIndex
                  className={
                    stepIndex === 2 ? "active" : stepIndex > 2 ? "completed" : ""
                  }
                >
                  {" "}
                  <span>2</span>
                </StepIndex>
                Step 2
              </StepItem>
              <StepItem className={stepIndex === 3 ? "active" : ""}>
                <StepIndex className={stepIndex === 3 ? "active" : ""}>
                  {" "}
                  <span>3</span>
                </StepIndex>
                Step 3
              </StepItem>
            </StepsBox>
          </div>
        </TopContainer>
        <BottomContainer>
          <Form>
            {stepIndex === 1 ? (
              <FirstStep
                selectedCategories = {selectedCategories}
                setSelectedCategories = {setSelectedCategories}
                setImageFiles = {setImageFiles}
                setVideoFiles = {setVideoFiles}
                setRecipeName = {setRecipeName}
                setRecipeType = {setRecipeType}
                categories = {categories}
                recipeType = {recipeType}
                recipeName = {recipeName}
                imageFiles = {imageFiles}
                videoFiles = {videoFiles}
                showErrors = {showErrors}
                setCategories = {setCategories}
              />
            ) : stepIndex === 2 ? (
              <SecondStep 
                imageFiles = {imageFiles}
                recipeName = {recipeName}
                recipeType = {recipeType}
                cookingTime = {cookingTime}
                servings = {servings}
                calory = {calory}
                categories = {categories}
                selectedCategories = {selectedCategories}
                setImageFiles = {setImageFiles}
                setCookingTime = {setCookingTime}
                setServings = {setServings}
                setCalory = {setCalory}
              />
            ) : stepIndex === 3 ? (
              <ThirdStep 
                imageFiles = {imageFiles}
                recipeName = {recipeName}
                recipeType = {recipeType}
                cookingTime = {cookingTime}
                servings = {servings}
                calory = {calory}
                ingredients = {ingredients}
                preparation = {preparation}
                showErrors = {showErrors}
                categories = {categories}
                selectedCategories = {selectedCategories}
                setImageFiles = {setImageFiles}
                setIngredients = {setIngredients}
                setPreparation = {setPreparation}
              />
            ) : ("")
            }
            <ButtonContainer className={stepIndex === 1 ? "step-one" : ""}>
              <PrimaryButton
                text={stepIndex === 1 ? "Cancel" : "Previous"}
                onclick={stepIndex === 1 ? handleCancel : handlePrevious}
                classname={stepIndex === 1 ? "cancel link" : "link"}
              />
              <PrimaryButton
                text={stepIndex === 3 ? "Submit" : "Next"}
                onclick={
                  stepIndex === 3
                    ? handleSubmit
                    : (event) => handleNext(event, stepIndex)
                }
              />
            </ButtonContainer>
          </Form>
        </BottomContainer>
      </>
      )}
    </MainContainer>
  );
}

const MainContainer = styled.div`
  width: 100%;
  min-height: 100vh;
`;
const TopContainer = styled.div`
  padding: 80px 0 30px;
  background-color: #f5f5f5;
  & .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media all and (max-width: 630px){
        flex-direction: column;
        gap: 10px;
    }
  }
`;
const Title = styled.h4`
  font-size: 24px;
  font-family: "dm_sansmedium";
`;
const StepsBox = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 60px;
  @media all and (max-width: 480px){
    grid-column-gap: 30px;
  }
  @media all and (max-width: 360px){
    grid-column-gap: 20px;
  }
`;
const StepItem = styled.li`
  font-size: 14px;
  font-family: "dm_sansmedium";
  color: ${colors.darkGray};
  display: flex;
  align-items: center;
  gap: 10px;
  &.active {
    color: ${colors.primary};
  }
  &.completed {
    color: ${colors.primary};
  }
`;
const StepIndex = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  // border: 2px solid ${colors.primary};
  // border: 2px solid ${colors.lightGray};
  padding: 3px;
  &.active {
    border: 2px solid ${colors.primary};
  }
  &.completed {
    border: none;
    & span {
      background-color: ${colors.secondary};
      color: ${colors.primary};
    }
  }
  & span {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: ${colors.lightGray};
    color: ${colors.darkGray};
    display: grid;
    place-items: center;
    font-size: 14px;
    font-family: "dm_sansregular";
  }
`;
const BottomContainer = styled.div`
  background-color: #fff;
  padding: 50px 0;
`;
const Form = styled.form`
  width: 55%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 50px;
  @media all and (max-width:1280px){
    width: 80%;
  }
  @media all and (max-width:768px){
    width: 90%;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
  border-top: 1px solid ${colors.lightGray};
  width: 100%;
  &.step-one {
    width: 70%;
    margin-left: auto;
  }
`;

export default AddRecipies;

import React, {useEffect} from 'react'
import styled from "styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";

import { colors } from "../../includes/constants/Colors";
import ViewGallerySlider from "../restuarant-single-page/ViewGallerySlider";
// images
import Alarm from "../../../assets/images/recipies/alarm.svg";
import Cooking from "../../../assets/images/recipies/cooking.svg";
import Fire from "../../../assets/images/recipies/fire.svg";
import Eye from "../../../assets/images/recipies/visibility.svg";
import Ingredients from "./includes/Ingredients";
import Preparations from "./includes/Preparations";
import Others from "./includes/Others";

const SingleRecipe = () => {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const details = searchParams?.get("details");
    const sliderData = [
        {
            id: 1,
            file_type: "10",
            file: "https://images.unsplash.com/photo-1565299624946-b28f40a0ae38?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8Zm9vZHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60",
        },
        {
            id: 2,
            file_type: "10",
            file: "https://images.unsplash.com/photo-1565299624946-b28f40a0ae38?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8Zm9vZHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60",
        },
        {
            id: 3,
            file_type: "10",
            file: "https://images.unsplash.com/photo-1565299624946-b28f40a0ae38?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8Zm9vZHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60",
        },
    ];

    useEffect(() => {
        window.scrollTo(0,0)
        let details = "ingredients";
        setSearchParams({ details });
    }, []);

  return (
    <BottomContainer>
        <div className="wrapper">
            <Back onClick={()=>navigate("/recipies")}>
                <img src={require("../../../assets/icons/arrow-left.svg").default} alt="back" />
                Back
            </Back>
            <BottomInnerContainer>
                <BottomRight>
                    <ViewGallerySlider data={sliderData} />
                    <ContentContainer>
                        <Heading>
                            Bulgogi beef triangle kimbub{" "}
                            <CategoryBox>
                                <CategoryIcon
                                    src="https://tegain-project-assets.sgp1.digitaloceanspaces.com/guester/icons/non-veg-icon.svg"
                                    alt="category-icon"
                                />
                            </CategoryBox>
                        </Heading>
                        <ProfileBox>
                            <ProfileImageBox>
                                <ProfileImg
                                    src="https://tegain-project-assets.sgp1.digitaloceanspaces.com/guester/profile-image.jpg"
                                    alt="profile-picture"
                                />
                            </ProfileImageBox>
                            <ProfileDetails>
                                <ProfileName>
                                    Andres Simran
                                </ProfileName>
                                <PostDate>
                                    Posted on 26th June
                                </PostDate>
                            </ProfileDetails>
                        </ProfileBox>
                        <RecipieDetails>
                            <RecipieDetailItem>
                                <RecipieDetailIcon>
                                    <RecipieDetailImg
                                        src={Alarm}
                                        alt="recipie-details-icon"
                                    />
                                </RecipieDetailIcon>
                                <RecipieDetailName>
                                    30 min
                                </RecipieDetailName>
                            </RecipieDetailItem>
                            <RecipieDetailItem>
                                <RecipieDetailIcon>
                                    <RecipieDetailImg
                                        src={Eye}
                                        alt="recipie-details-icon"
                                    />
                                </RecipieDetailIcon>
                                <RecipieDetailName>
                                    200 Views
                                </RecipieDetailName>
                            </RecipieDetailItem>
                            <RecipieDetailItem>
                                <RecipieDetailIcon>
                                    <RecipieDetailImg
                                        src={Cooking}
                                        alt="recipie-details-icon"
                                    />
                                </RecipieDetailIcon>
                                <RecipieDetailName>
                                    3 People Servings
                                </RecipieDetailName>
                            </RecipieDetailItem>
                            <RecipieDetailItem>
                                <RecipieDetailIcon>
                                    <RecipieDetailImg
                                        src={Fire}
                                        alt="recipie-details-icon"
                                    />
                                </RecipieDetailIcon>
                                <RecipieDetailName>
                                    258 Cal
                                </RecipieDetailName>
                            </RecipieDetailItem>
                        </RecipieDetails>
                        <DishCategory>
                            <DishCategoryItem>
                                Snacks
                            </DishCategoryItem>
                            <DishCategoryItem>
                                Non Veg
                            </DishCategoryItem>
                            <DishCategoryItem>
                                Breakfast
                            </DishCategoryItem>
                        </DishCategory>
                    </ContentContainer>
                    <TabsDetails>
                        <Tabs>
                            <TabItem
                                onClick={() => {
                                    let details = "ingredients";
                                    setSearchParams({
                                        details,
                                    });
                                }}
                            >
                                Ingredients
                            </TabItem>
                            <TabItem
                                onClick={() => {
                                    let details = "preparation";
                                    setSearchParams({
                                        details,
                                    });
                                }}
                            >
                                Preparation
                            </TabItem>
                            <TabItem
                                onClick={() => {
                                    let details = "Other";
                                    setSearchParams({
                                        details,
                                    });
                                }}
                            >
                                Other
                            </TabItem>
                        </Tabs>
                        <TabContent>
                            {details === "ingredients" ? (
                                <Ingredients />
                            ) : details === "preparation" ? (
                                <Preparations />
                            ) : details === "Other" ? (
                                <Others />
                            ) : null}
                        </TabContent>
                    </TabsDetails>
                </BottomRight>
            </BottomInnerContainer>
        </div>
    </BottomContainer>
  )
}

export default SingleRecipe

const Back = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    color: ${colors.darkGray};
    cursor: pointer;
    img{
        display: block;
        width: 100%;
        height: 100%;
    }
`;
const BottomContainer = styled.div`
    background-color: #fff;
    padding: 50px 0;
`;
const BottomInnerContainer = styled.div`
    width: 100%;
`;
const BottomRight = styled.div`
    width: 80%;
    margin: 0 auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0px 4px 56px rgba(0, 0, 0, 0.45);
`;
const ContentContainer = styled.div`
    padding: 15px 0;
    margin-bottom: 20px;
`;
const Heading = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 10px;
    font-family: "dm_sansmedium";
    font-size: 18px;
    margin-bottom: 10px;
`;
const CategoryBox = styled.span`
    width: 20px;
`;
const CategoryIcon = styled.img``;
const ProfileBox = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
`;
const ProfileImageBox = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 2px;
    border: 1px solid #c5c5c5;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const ProfileImg = styled.img`
    width: 100%;
    height: 100%;
`;
const ProfileDetails = styled.div``;
const ProfileName = styled.div`
    font-family: "dm_sansmedium";
    font-size: 16px;
`;
const PostDate = styled.div`
    font-family: "dm_sansregular";
    font-size: 14px;
`;
const RecipieDetails = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 5px;
    width: fit-content;
    margin-bottom: 20px;
    @media all and (max-width: 510px){
        grid-template-columns: 1fr;
    }
`;
const RecipieDetailItem = styled.div`
    // width: 200px;
    display: flex;
    align-items: center;
    gap: 5px;
`;
const RecipieDetailIcon = styled.div`
    width: 20px;
`;
const RecipieDetailImg = styled.img`
    width: 100%;
`;
const RecipieDetailName = styled.div`
    font-family: "dm_sansregular";
    font-size: 14px;
`;
const DishCategory = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
`;
const DishCategoryItem = styled.div`
    border-radius: 16px;
    padding: 6px 10px;
    background-color: #f3f4f5;
    font-family: "dm_sansregular";
    font-size: 14px;
    color: #a0a0a0;
`;
const TabsDetails = styled.div``;
const Tabs = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 30px;
    width: 100%;
    overflow-x: scroll;
`;
const TabItem = styled.div`
    font-family: "dm_sansmedium";
    font-size: 16px;
    border-bottom: 2px solid ${colors.primary};
    width: fit-content;
    padding-bottom: 3px;
    cursor: pointer;
`;
const TabContent = styled.div``;

import React, { useContext } from "react";
import styled from "styled-components";
import { createSearchParams, useNavigate } from "react-router-dom";

// constants
import { colors } from "./constants/Colors";
import { Context } from "../../contexts/Store";
// components
import NoResultsFound from "./modal/spotlight-search-modal/NoResultsFound";

export default function TopSearchModal({
    setSearchParams,
    searchResults,
    setIsSearchModal,
    querry,
    searchTerm,
    setSearchTerm,
}) {
    const navigate = useNavigate();
    const { dispatch } = useContext(Context);
    const clickPress = (event) => {
        if (event.keyCode == 13) {
            navigate({
                pathname: "/explore-food",
                search: `?${createSearchParams({
                    q: String(querry),
                })}`,
            });
            setIsSearchModal(false);
        }
    };
    return (
        <DropDownContainer>
            <TopContainer>
                <SearchContainer>
                    <SearchFieldInnerContainer>
                        <SearchIcon
                            onClick={() => {
                                navigate({
                                    pathname: "/explore-food",
                                    search: `?${createSearchParams({
                                        q: String(querry),
                                    })}`,
                                });
                                setIsSearchModal(false);
                            }}
                        >
                            <SearchIconImg
                                src="https://tegain-project-assets.sgp1.digitaloceanspaces.com/guester/icons/search.svg"
                                alt="search"
                            />
                        </SearchIcon>
                        <Search
                            type="search"
                            name="search-form"
                            id="search-form"
                            className="search-input"
                            placeholder="Search"
                            autoComplete="off"
                            value={searchTerm ? searchTerm : ""}
                            autoFocus
                            onChange={(e) => {
                                let q = e.target.value;
                                if (q) {
                                    setSearchTerm(q);
                                    setSearchParams({ q });
                                } else {
                                    setSearchTerm(q);
                                    setSearchParams({});
                                }
                            }}
                            onKeyDown={(event) => clickPress(event)}
                        />
                    </SearchFieldInnerContainer>
                    {querry && (
                        <CloseButton
                            src="https://tegain-project-assets.sgp1.digitaloceanspaces.com/guester/icons/close-searchmodal.svg"
                            onClick={() => {
                                setSearchParams({});
                                setSearchTerm("");
                                setIsSearchModal(false);
                                dispatch({
                                    type: "HEADER_SEARCH",
                                    payload: false,
                                });
                            }}
                        />
                    )}
                </SearchContainer>
            </TopContainer>
            {searchResults?.shops?.length === 0 &&
            searchResults?.dishes?.length === 0 ? (
                <NoResultsFound />
            ) : (
                <DropDown>
                    <SearchCategory>
                        Restaurant
                        <Results>
                            {searchResults?.shops?.length === 0 ? (
                                <NoResultsFound />
                            ) : (
                                searchResults?.shops
                                    ?.slice(0, 3)
                                    .map((data) => (
                                        <SearchItem
                                            onClick={() => {
                                                navigate(
                                                    `/restuarant/view/${data.shop_pk}/cuisines-and-menu/`
                                                );
                                                setSearchTerm("");
                                                setIsSearchModal(false);
                                            }}
                                        >
                                            <Left>
                                                <LeftImageContainer>
                                                    <LeftImage
                                                        src={data?.logo}
                                                        alt="shop-logo"
                                                    />
                                                </LeftImageContainer>
                                                <Details>
                                                    <Name>
                                                        {data?.shop_name}
                                                    </Name>
                                                    <CardDetails>
                                                        Restaurant
                                                    </CardDetails>
                                                </Details>
                                            </Left>
                                            {data?.distance && (
                                                <Distance>
                                                    {data?.distance}
                                                </Distance>
                                            )}
                                        </SearchItem>
                                    ))
                            )}
                        </Results>
                    </SearchCategory>
                    <SearchCategory>
                        Food
                        <Results>
                            {searchResults?.dishes?.length === 0 ? (
                                <NoResultsFound />
                            ) : (
                                searchResults?.dishes
                                    ?.slice(0, 3)
                                    ?.map((data) => (
                                        <SearchItem
                                            onClick={() => {
                                                navigate(
                                                    `/restuarant/view/${data.shop_pk}/cuisines-and-menu/`
                                                );
                                                setSearchTerm("");
                                                setIsSearchModal(false);
                                            }}
                                        >
                                            <Left>
                                                <LeftImageContainer>
                                                    <LeftImage
                                                        src={data?.image}
                                                        alt="food-image"
                                                    />
                                                </LeftImageContainer>
                                                <Details>
                                                    <Name>{data?.name}</Name>
                                                    <CardDetails>
                                                        Food
                                                    </CardDetails>
                                                </Details>
                                            </Left>
                                        </SearchItem>
                                    ))
                            )}
                        </Results>
                    </SearchCategory>
                </DropDown>
            )}
        </DropDownContainer>
    );
}

const DropDownContainer = styled.div`
    border-radius: 16px;
    padding: 20px 16px;
    max-height: 450px;
    overflow-x: hidden;
    overflow: scroll;
    ::-webkit-scrollbar {
        display: block;
    }
`;

const CloseButton = styled.img`
    cursor: pointer;
    width: 20px;
    height: 20px;
`;
const SearchItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid #f3f4f5;
    cursor: pointer;
`;
const Left = styled.div`
    display: flex;
    align-items: center;
    @media all and (max-width: 640px) {
        flex-wrap: wrap;
    }
`;
const LeftImageContainer = styled.div`
    height: 60px;
    width: 60px;
    border-radius: 8px;
    margin-right: 10px;
    overflow: hidden;
    @media all and (max-width: 768px) {
        height: 50px;
        width: 50px;
    }
    @media all and (max-width: 640px) {
        margin-bottom: 10px;
    }
`;
const Details = styled.div`
    @media all and (max-width: 640px) {
        width: 100%;
    }
`;
const LeftImage = styled.img`
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
`;
const Name = styled.h3`
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.1px;
    color: ${colors.fontColorPrimary};
    margin-bottom: 5px;
    font-family: "dm_sansmedium";
    @media all and (max-width: 980px) {
        font-size: 15px;
    }
    @media all and (max-width: 768px) {
        font-size: 14px;
        margin-bottom: 0;
    }
`;
const Distance = styled.div`
    padding: 8px 12px;
    width: fit-content;
    background: ${colors.secondary};
    border-radius: 9.60748px;
    color: #20652a;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    letter-spacing: 0.1px;
    font-weight: 500;
    @media all and (max-width: 768px) {
        font-size: 12px;
        padding: 6px 10px;
    }
    @media all and (max-width: 640px) {
        margin-bottom: auto;
        width: 60px;
        padding: 5px 8px;
    }
`;
const CardDetails = styled.div`
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #a0a0a0;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    @media all and (max-width: 768px) {
        font-size: 12px;
    }
`;
const DropDown = styled.div`
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: ${colors.fontColorPrimary};
    font-family: "dm_sansmedium";
`;
const Results = styled.div`
    /* margin-top: 5px; */
`;
const SearchCategory = styled.div`
    margin-bottom: 15px;
    :last-child {
        margin-bottom: 0;
    }
`;
const TopContainer = styled.div``;
const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #a0a0a0;
    margin-right: 10px;
    height: 45px;
    @media all and (max-width: 540px) {
        height: 40px;
    }
`;
const SearchFieldInnerContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;
const SearchIcon = styled.div`
    width: 20px;
    margin-right: 5px;
    cursor: pointer;
`;
const SearchIconImg = styled.img`
    display: block;
    width: 100%;
`;
const Search = styled.input`
    text-decoration: none;
    opacity: 1;
    padding: 12px 0;
    border-radius: 6px;
    width: 100%;
    :focus {
        outline: none;
    }
    ::placeholder {
        font-family: "dm_sansregular";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: #a0a0a0;
    }
`;

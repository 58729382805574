import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

// constants
import { Context } from "../../contexts/Store";
import { colors } from "./constants/Colors";

// components
import TopSearchModal from "./TopSearchModal";

export default function Header({
    setProfileMenu,
    isProfileMenu,
    setLocationModal,
    setAuthModal,
    error,
    setIsSearchModal,
    isSearchModal,
    searchResults,
    setHamburgerModal,
    handleSearch,
}) {
    const { state, dispatch } = useContext(Context);
    const splitAddress = state.location_details.address?.split(",");
    const isHeaderSearch = state.is_header_search;
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchTerm, setSearchTerm] = useState("");
    const navigate = useNavigate();
    //get search params
    const querry = searchParams.get("q");

    const handleChange = (text) => {
        if (text != "") {
            dispatch({
                type: "HEADER_SEARCH",
                payload: true,
            });
        } else {
            dispatch({
                type: "HEADER_SEARCH",
                payload: false,
            });
        }
    };
    // useEffect(() => {
    //     if (!querry) {
    //         setIsSearchModal(false);
    //     }
    // }, [searchParams]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (isHeaderSearch) {
                handleSearch();
            }
        }, 3000);
        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);
    return (
        <Container id="header">
            {(isSearchModal || searchTerm) && (
                <Overlay
                    onClick={() => {
                        setSearchParams({});
                        setIsSearchModal(false);
                        setSearchTerm("");
                        dispatch({
                            type: "HEADER_SEARCH",
                            payload: false,
                        });
                    }}
                ></Overlay>
            )}
            <LeftContainer>
                <LogoContainer
                    onClick={() => {
                        error && dispatch({ type: "RESET_ERROR" });
                        navigate("/");
                    }}
                >
                    <Logo
                        src={
                            require("../../assets/icons/logo/named-logo.svg")
                                .default
                        }
                    />
                </LogoContainer>
                <LocationContainer
                    onClick={() => setLocationModal(true)}
                    error={error}
                >
                    <LocationIcon>
                        <LocationIconImg
                            src={
                                require("../../assets/icons/location.svg")
                                    .default
                            }
                        />
                    </LocationIcon>
                    <LocationTextContainer>
                        {state.location_details.address && (
                            <LocationText>
                                {splitAddress[0]},{splitAddress[1]},{" "}
                                {splitAddress[2]}
                            </LocationText>
                        )}
                    </LocationTextContainer>
                    <ArrowIconBox>
                        <ArrowIcon
                            src={
                                require("../../assets/icons/down-arrow.svg")
                                    .default
                            }
                        />
                    </ArrowIconBox>
                </LocationContainer>
            </LeftContainer>
            <MiddleContainer className="menu" error={error}>
                <NavigationLink end to="/">
                    Home
                </NavigationLink>
                <NavigationLink to="explore-food">Explore Food</NavigationLink>
                <NavigationLink to="community">Community</NavigationLink>
                <NavigationLink to="offers">Offers</NavigationLink>
                <NavigationLink to="recipies">Recipes</NavigationLink>
            </MiddleContainer>
            <RightContainer error={error}>
                <SearchField>
                    <ResponsiveSearchIcon
                        onClick={() => setIsSearchModal(true)}
                    >
                        <SearchIconImg
                            src="https://tegain-project-assets.sgp1.digitaloceanspaces.com/guester/icons/search.svg"
                            alt="search"
                        />
                    </ResponsiveSearchIcon>
                    <SearchFieldInnerContainer isSearchModal={isSearchModal}>
                        <SearchIcon>
                            <SearchIconImg
                                src="https://tegain-project-assets.sgp1.digitaloceanspaces.com/guester/icons/search.svg"
                                alt="search"
                            />
                        </SearchIcon>
                        <Search
                            type="search"
                            name="search-form"
                            id="search-form"
                            className="search-input"
                            placeholder="Search"
                            autoComplete="off"
                            value={searchTerm}
                            onChange={(event) => {
                                setIsSearchModal(true);
                                setSearchTerm(event.target.value);
                                handleChange(event.target.value);
                                let q = event.target.value;
                                if (q) {
                                    setSearchParams({ q });
                                } else {
                                    setSearchParams({});
                                }
                            }}
                        />
                    </SearchFieldInnerContainer>
                    {(isSearchModal || searchTerm) && (
                        <SearchModal>
                            <TopSearchModal
                                setSearchParams={setSearchParams}
                                searchParams={searchParams}
                                searchResults={searchResults}
                                isSearchModal={isSearchModal}
                                setIsSearchModal={setIsSearchModal}
                                searchTerm={searchTerm}
                                setSearchTerm={setSearchTerm}
                                querry={querry}
                            />
                        </SearchModal>
                    )}
                </SearchField>
                <RightNav>
                    <RightNavIcon className="notification">
                        <RightNavIconImg src="https://tegain-project-assets.sgp1.digitaloceanspaces.com/guester/icons/notification.svg" />
                    </RightNavIcon>
                    <RightNavIcon
                        onClick={() => {
                            let action = "login";
                            state.user_details.is_verified
                                ? navigate("/favourites/restuarants")
                                : setSearchParams({ action });
                        }}
                    >
                        <RightNavIconImg src="https://tegain-project-assets.sgp1.digitaloceanspaces.com/guester/icons/favourite.svg" />
                    </RightNavIcon>
                    <ProfileNav>
                        {state.user_details.is_verified ? (
                            <RightNavIcon>
                                <RightNavIconImg
                                    src="https://tegain-project-assets.sgp1.digitaloceanspaces.com/guester/icons/profile.svg"
                                    onClick={() => {
                                        setProfileMenu(!isProfileMenu);
                                    }}
                                />
                            </RightNavIcon>
                        ) : (
                            <ButtonContainer>
                                <Login
                                    onClick={() => {
                                        setAuthModal(true);
                                        setProfileMenu(false);
                                        let action = "login";
                                        setSearchParams({ action });
                                    }}
                                >
                                    Login{""}
                                </Login>
                                &nbsp;/&nbsp;
                                <Signup
                                    onClick={() => {
                                        setAuthModal(true);
                                        setProfileMenu(false);
                                        let action = "signup";
                                        setSearchParams({ action });
                                    }}
                                >
                                    Signup
                                </Signup>
                            </ButtonContainer>
                        )}
                    </ProfileNav>
                </RightNav>
                <HamburgerIcon onClick={() => setHamburgerModal(true)}>
                    <HamburgerIconImg
                        src="https://tegain-project-assets.sgp1.digitaloceanspaces.com/guester/icons/hamburger.svg"
                        alt="Hamburger"
                    />
                </HamburgerIcon>
            </RightContainer>
        </Container>
    );
}
const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    box-shadow: 0px 9px 25px rgba(0, 0, 0, 0.09);
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 500;
    background-color: #fff;
    @media all and (max-width: 640px) {
        padding: 20px;
    }
`;
const LogoContainer = styled.div`
    width: 150px;
    margin-right: 15px;
    cursor: pointer;
    @media all and (max-width: 1080px) {
        margin-right: 10px;
    }
    @media all and (max-width: 980px) {
        width: 130px;
    }
    @media all and (max-width: 640px) {
        width: 110px;
        margin-bottom: 5px;
    }
    @media all and (max-width: 360px) {
        width: 100px;
    }
`;
const Logo = styled.img`
    display: block;
    width: 100%;
`;
const LeftContainer = styled.div`
    display: flex;
    align-items: center;
    width: fit-content;
    @media all and (max-width: 640px) {
        display: block;
    }
`;
const HamburgerIcon = styled.div`
    width: 20px;
    display: none;
    position: relative;
    cursor: pointer;
    @media all and (max-width: 1245px) {
        display: block;
    }
    @media all and (max-width: 480px) {
        margin-right: 10px;
    }
    @media all and (max-width: 360px) {
        width: 18px;
    }
`;
const HamburgerIconImg = styled.img`
    display: block;
    width: 100%;
`;
const LocationContainer = styled.div`
    background: #f3f4f5;
    border-radius: 71px;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    font-size: 16px;
    font-family: "dm_sansregular";
    color: ${colors.fontColorPrimary};
    padding: 13px 16px;
    cursor: pointer;
    width: fit-content;
    display: ${({ error }) => (error ? "none" : "flex")};
    @media all and (max-width: 768px) {
        font-size: 14px;
    }
    @media all and (max-width: 640px) {
        background-color: transparent;
        padding: 0;
    }
`;
const LocationIcon = styled.div`
    margin-right: 5px;
    width: 20px;
`;
const LocationIconImg = styled.img`
    display: block;
    width: 100%;
`;
const LocationTextContainer = styled.div`
    overflow-x: scroll;
    max-width: 200px;
    @media all and (max-width: 1345px) {
        max-width: 140px;
    }
    @media all and (max-width: 1280px) {
        max-width: 100px;
    }
    @media all and (max-width: 640px) {
        max-width: 200px;
    }
    @media all and (max-width: 420px) {
        max-width: 100px;
    }
`;
const LocationText = styled.span`
    font-size: 14px;
    white-space: nowrap;
    @media all and (max-width: 640px) {
        font-size: 12px;
    }
`;
const ArrowIconBox = styled.div`
    width: 13px;
    margin-left: 10px;
    @media all and (max-width: 640px) {
        width: 11px;
    }
`;
const ArrowIcon = styled.img`
    display: block;
    width: 100%;
`;
const MiddleContainer = styled.div`
    display: ${({ error }) => (error ? "none" : "flex")};
    @media all and (max-width: 1245px) {
        display: none;
    }
    align-items: center;
    justify-content: center;
    width: fit-content;
`;
const NavigationLink = styled(NavLink)`
    padding: 8px 15px;
    border-radius: 24px;
    margin-right: 15px;
    text-decoration: none;
    font-size: 16px;
    color: ${colors.fontColorPrimary};
    font-family: "dm_sansmedium";
    display: inline-block;
    &.active {
        background: ${colors.secondary};
    }
    &:hover {
    }
    &:last-child {
        margin-right: 0;
    }
    @media all and (max-width: 1100px) {
        padding: 7px 13px;
        margin-right: 10px;
    }
`;
const RightContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    position: relative;
    display: ${({ error }) => (error ? "none" : "flex")};
    @media all and (max-width: 480px) {
        gap: 10px;
    }
`;
const SearchField = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #e3e3e3;
    border-radius: 25px;
    width: 200px;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: ${colors.fontColorSecondary};
    position: relative;
    @media all and (max-width: 768px) {
        width: 150px;
        border: 0;
        width: fit-content;
    }
`;
const SearchFieldInnerContainer = styled.div`
    display: flex;
    align-items: center;
    padding: ${({ isSearchModal }) => (isSearchModal ? "0" : "0 10px")};
    @media all and (max-width: 768px) {
        display: none !important;
    }
    width: 100%;
`;
const Overlay = styled.div`
    position: fixed;
    width: 100%;
    left: 0;
    height: 100vh;
    top: 0;
    cursor: pointer;
    z-index: 105;
    background: #a5a5a5;
    opacity: 0.4;
    transition: all 0.4s ease;
`;
const SearchModal = styled.div`
    position: absolute;
    right: 0;
    z-index: 10;
    top: 0;
    width: 500px;
    background-color: #fff;
    border-radius: 16px;
    overflow: hidden;
    z-index: 105;
    box-shadow: 0px 9px 25px rgb(0 0 0 / 9%);
    @media all and (max-width: 768px) {
        width: 400px;
    }
    @media all and (max-width: 640px) {
        width: 350px;
    }
    @keyframes popup {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @media all and (max-width: 500px) {
        width: 310px;
    }
    @media all and (max-width: 480px) {
        position: fixed;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
    }
    @media all and (max-width: 360px) {
        width: 300px;
    }
`;

const Search = styled.input`
    text-decoration: none;
    opacity: 1;
    padding: 10px 0;
    border-radius: 6px;
    background-color: transparent;
    flex: 1;
    :focus {
        outline: none;
    }
    ::placeholder {
        font-family: "dm_sansregular";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: #a0a0a0;
    }
    @media all and (max-width: 768px) {
        display: none;
    }
`;
const SearchIcon = styled.div`
    width: 20px;
    margin-right: 5px;
`;
const ResponsiveSearchIcon = styled.div`
    width: 20px;
    display: none;
    @media all and (max-width: 768px) {
        display: block;
    }
`;
const SearchIconImg = styled.img`
    display: block;
    width: 100%;
`;
const RightNav = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    @media all and (max-width: 480px) {
        gap: 10px;
    }
`;
const RightNavIcon = styled.div`
    cursor: pointer;
    width: 20px;
    &.search {
        display: none;
        z-index: 10;
        cursor: pointer;
        @media all and (max-width: 1024px) {
            display: block;
            position: relative;
        }
    }

    /* &.logout-profile-icon {
        display: none;
        &.logout-profile-icon.logout-active {
            @media all and (max-width: 1024px) {
                display: block;
            }
        }
    } */

    @media all and (max-width: 768px) {
        &.notification {
            display: none;
        }
    }
    @media all and (max-width: 360px) {
        width: 18px;
    }
`;
const ProfileNav = styled.div`
    @media all and (max-width: 1150px) {
        display: none;
    }
`;
const RightNavIconImg = styled.img`
    display: block;
    width: 100%;
`;
const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    background: ${colors.primary};
    color: #fff;
    padding: 10px;
    border-radius: 8px;
    font-size: 18px;
    line-height: 19px;
    font-family: "dm_sansmedium";
    @media all and (max-width: 1150px) {
        display: none;
    }
`;
const Login = styled.span`
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.1px;
    cursor: pointer;
`;
const Signup = styled(Login)``;
const Menu = styled.div``;
const Item = styled.h4`
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: ${colors.fontColorPrimary};
    border-bottom: 1px solid #fff;
    :hover {
        border-bottom: 1px solid #f3f4f5;
    }
    :last-child {
        margin-bottom: 0;
    }
`;
